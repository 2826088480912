// Retrieve config data from session storage
export const getConfig = () => {
    const config = sessionStorage.getItem('config')
    return config ? JSON.parse(config) : null
}

// Set config to browser storage
export const setConfig = (config) => {
    sessionStorage.setItem('config', JSON.stringify(config))
}

// Remove config from browser storage
export const removeConfig = () => {
    sessionStorage.removeItem('config')
}