import axios from "axios"
import Checkbox from "components/forms/checkbox"
import ErrorMessage from "components/forms/error-message"
import Input from "components/forms/input"
import Label from "components/forms/label"
import { lang } from "config"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useLayoutEffect } from "react"
import toast from "react-hot-toast"
import useApplicationRepositories from "repositories/application"
import useBranches from "repositories/branch"
import usePermissionRepositories from "repositories/permission"

const Form = ({ data, errors, onSubmit }) => {
    const id = data?.id

    // const [branch, setBranch] = useState(data?.branch_id ?? null)
    const [application, setApplication] = useState(data?.application_id ?? null)
    const [name, setName] = useState(data?.name ?? null)
    const [permission, setPermission] = useState([])

    // Selections
    // const { data: branchSelection, isLoading: isLoadingBranchSelection } = useBranches({
    //     paginate: false
    // })
    const { data: applicationSelection, isLoading: isLoadingApplicationSelection } = useApplicationRepositories({
        paginate: false
    })
    const { data: permissionSelection, isLoading: isLoadingPermissionSelection } = usePermissionRepositories({
        group: true,
        application_id: application
    })

    useEffect(() => {
        if (!isLoadingPermissionSelection && data?.permissions) {
            var permissions = []

            Object.keys(permissionSelection)?.map((permission) => {
                permissionSelection[permission]?.map((row) => {
                    if (data?.permissions.find((data) => data.id === row.id)) {
                        permissions.push(row)
                    }
                })
            })

            setPermission(permissions)
        }
    }, [isLoadingPermissionSelection])

    const handleChangePermission = async (value) => {
        console.log(value)
        if (permission?.find((row) => row.id === value.id)) {
            var permissions = []

            if (value.node.substr(value.node.length - 4) === 'view') {
                permissions = permission.filter((row) => row.page !== value.page)
            } else {
                permissions = permission.filter((row) => row.id !== value.id)
            }

            console.log(`Included, updating to: ${permission.filter((row) => row.id !== value.id)}`)
            setPermission(permissions)
        } else {
            console.log(`Not included`)
            setPermission([...permission, value])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const pluckPermission = _.map(permission, 'id')

        onSubmit({
            id,
            application_id: application,
            name,
            permission: pluckPermission
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-3xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-4">
                                    {/* <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="branch" value="Branch" />
                                        <select onChange={(e) => setBranch(e.target.value)} value={branch} name="branch" id="branch" className={`${errors.branch_id ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- Choose Branch --</option>
                                            {branchSelection?.length !== 0 && branchSelection?.map(row => (
                                                <option value={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage error={errors.branch_id} />
                                    </div> */}

                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="application" value="Application" />
                                        <select onChange={(e) => setApplication(e.target.value)} value={application} name="application" id="application" className={`${errors.application_id ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- Choose Application --</option>
                                            {applicationSelection?.length !== 0 && applicationSelection?.map(row => (
                                                <option value={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage error={errors.application_id} />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="name" value={lang.name} />
                                        <Input onChange={e => setName(e.target.value)} value={name} error={errors?.name} id="name" />
                                        <ErrorMessage error={errors?.name} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.permissions}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.more_detailed_form_about_role_permissions}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className={`${errors?.permission ? 'border-red-200' : ''} border sm:rounded-3xl`}>
                            <div className="px-4 py-5 space-y-6 sm:p-6">
                                <ErrorMessage error={errors?.permission} />
                                {!isLoadingPermissionSelection && application && Object.keys(permissionSelection).map((permissions, index) => (
                                    <fieldset>
                                        <legend className="sr-only">{permissions}</legend>
                                        <div className="text-base font-medium text-gray-900 capitalize" aria-hidden="true">
                                            {permissions}
                                        </div>
                                        <div className="mt-4 space-y-4">
                                            {permissionSelection[permissions].map((row) => (
                                                <div className="flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <Checkbox disabled={(!permission?.find((permission) => permission.page === row.page) && row.node?.substr(row.node.length - 4) !== 'view')} id={`${row.page}.${row.action}`} onChange={(e) => handleChangePermission(row)} value={row} checked={permission?.find((permission) => permission.id === row.id)} />
                                                        {/* <input onChange={(e) => handleChangePermission(e.target.value)} value={row.id} checked={permission?.includes(row.id)} id={`${row.page}.${row.action}`} name={`${row.page}.${row.action}`} type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" /> */}
                                                    </div>
                                                    {/* {JSON.stringify(permission?.find((permission) => permission.id === row.id))} */}
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor={`${row.page}.${row.action}`} className={`${(!permission?.find((permission) => permission.page === row.page) && row.node?.substr(row.node.length - 4) !== 'view') ? 'opacity-50' : ''} font-medium text-gray-700 capitalize`}>
                                                            {row.action}
                                                        </label>
                                                        <p className="text-gray-500">{row.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </fieldset>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{data ? lang.update : lang.create}</span>
                </button>
            </div>
        </form>
    )
}

export default Form