const BranchIcon = ({ className, stroke }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={24} height={24} viewBox="0 0 24 24" strokeWidth={stroke ?? 1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx={7} cy={18} r={2}></circle>
            <circle cx={7} cy={6} r={2}></circle>
            <circle cx={17} cy={6} r={2}></circle>
            <line x1={7} y1={8} x2={7} y2={16}></line>
            <path d="M9 18h6a2 2 0 0 0 2 -2v-5"></path>
            <polyline points="14 14 17 11 20 14"></polyline>
        </svg>
    )
}

export default BranchIcon