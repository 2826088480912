import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import nProgress from 'nprogress'
import Form from './form'
import toast from 'react-hot-toast'
import { lang } from 'config'

const Content = ({ data, success }) => {
    const id = data.id

    const [errors, setErrors] = useState({})

    const handleSubmit = (data) => {
        nProgress.start()

        axios.patch(`${process.env.REACT_APP_GENERIC_API_URL}/user/map/${id}`, {
            id, 
            user_id: data.user_id,
            role_id: data.role_id,
            branch_id: data.branch_id
        }).then((response) => {
            success()
            toast.success(response.data.message)
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            toast.error(error.response.data.message)
            console.log(error)
            nProgress.done()
        })
    }

    return (
        <Form data={data} errors={errors} onSubmit={handleSubmit} />
    )
}

const Edit = ({ data, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSuccess = () => {
        closeModal()
        onSuccess()
    }

    return (
        <>
            <button disabled={data.role?.is_super} onClick={openModal} class={`${data.role?.is_super ? 'opacity-50 bg-neutral-900' : 'bg-neutral-800'} inline-flex items-center p-1 text-white transition bg-neutral-800 rounded-full active:hover:scale-90`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                </svg>
            </button>
            <Modal size="4xl" isOpen={isOpen} onClose={closeModal} title={`${lang.edit} ${lang.data}`} content={<Content data={data} success={handleSuccess} />} />
        </>
    )
}

export default Edit