const ErrorMessage = ({ error }) => {
    return (
        <>
            {error && error.map((row, index) => (
                <>
                    <span className="text-xs leading-none text-red-500">{row}</span>
                    <br />
                </>
            ))}
        </>
    )
}

export default ErrorMessage