import { useState, useEffect } from "react"

import moment from "moment"
import useBranches from "repositories/branch"
import nProgress from "nprogress"
import ErrorMessage from "components/forms/error-message"
import _ from "lodash"
import InputAmount from "components/forms/input-amount"
import { NumericFormat } from "react-number-format"
import InputDate from "components/forms/input-date"
import { useAuth } from "contexts/auth-context"
import { lang } from "config"
import useUsers from "repositories/user"
import useRoles from "repositories/role"
import SelectDescription from "components/forms/select-description"
import { usePage } from "contexts/page-context"

const Form = ({ data, errors, onSubmit }) => {
    const { configuration } = usePage()

    const { data: userSelection, isLoading: isLoadingUser } = useUsers({
        paginate: false
    })
    const { data: roleSelection, isLoading: isLoadingRole } = useRoles({
        paginate: false
    })
    const { data: branchSelection, isLoading: isLoadingBranch } = useBranches({
        paginate: false
    })

    const [user, setUser] = useState(data?.user_id ?? null)
    const [role, setRole] = useState(data?.role_id ?? null)
    const [branch, setBranch] = useState(data?.branch_id ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit({
            user_id: user,
            role_id: role,
            branch_id: branch
        })
    }

    useEffect(() => {
        setUser(data?.user_id ?? null)
        setRole(data?.role_id ?? null)
        setBranch(data?.branch_id ?? 1)
    }, [data])

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                <div>
                    <label for="branch" className="block text-xs text-neutral-700">{lang.branch}</label>
                    <SelectDescription disabled={configuration('site') === 'hq'} isLoading={isLoadingBranch} onChange={(value) => setBranch(value)} title={(value) => value.name} description={(value) => value.code} value={branch} keyValue={(value) => value.id} selection={branchSelection} placeholder={`-- ${lang.choose} ${lang.branch} --`} error={errors.branch_id} />
                    <ErrorMessage error={errors.branch_id} />
                </div>

                <div>
                    <label for="user" className="block text-xs text-neutral-700">{lang.user}</label>
                    {/* <select onChange={(e) => setUser(e.target.value)} value={user} name="user" id="user" className={`${errors.user_id ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        {isLoadingUser && (
                            <option value={null} disabled selected>{lang.loading}</option>
                        )}
                        {!isLoadingUser && (
                            <option value={null} disabled selected>{`-- ${lang.choose} ${lang.user} --`}</option>
                        )}
                        {!isLoadingUser && userSelection.map(row => (
                            <option value={row.id}>{row.name}</option>
                        ))}
                    </select> */}
                    <SelectDescription isLoading={isLoadingUser} onChange={(value) => setUser(value)} title={(value) => value.name} description={(value) => value.phone} value={user} keyValue={(value) => value.id} selection={userSelection} placeholder={`-- ${lang.choose} ${lang.user} --`} error={errors.user_id} />
                    <ErrorMessage error={errors.user_id} />
                </div>

                <div>
                    <label for="role" className="block text-xs text-neutral-700">{lang.role}</label>
                    <SelectDescription isLoading={isLoadingRole} onChange={(value) => setRole(value)} title={(value) => value.name} value={role} keyValue={(value) => value.id} selection={roleSelection} placeholder={`-- ${lang.choose} ${lang.role} --`} error={errors.role_id} />
                    <ErrorMessage error={errors.role_id} />
                </div>
            </div>
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}

export default Form