import axios from "axios"
import useSWR from "swr"

const fetcher = async (url, params) => {
    return await axios.get(url, { params }).then((res) => res.data.data)
}

const usePermissionRepositories = (params) => {
    const { data, error, mutate, isValidating } = useSWR([`${process.env.REACT_APP_GENERIC_API_URL}/permission`, params], fetcher)

    return {
        data,
        isLoading: !error && !data,
        error,
        isValidating,
        mutate
    }
}

export default usePermissionRepositories