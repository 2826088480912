import { PageProvider } from "contexts/page-context"
import { getUser } from "helpers/auth-helper"
import { Navigate } from "react-router-dom"

const GuestRoute = ({ children }) => {
    const user = getUser()

    if (user) {
        return <Navigate to="/" replace />
    }

    return (
        <PageProvider>
            {children}
        </PageProvider>
    )
}

export default GuestRoute