import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import _ from "lodash"
import Label from "components/forms/label"
import Input from "components/forms/input"
import { lang } from "config"
import useCountries from "repositories/country"
import SelectCountry from "components/forms/select-country"
import { generatePhoneNumber, removeCountryCodeFromPhoneNumber } from "helpers/phone-helper"
import Description from "components/forms/description"

const Form = ({ data = {}, errors, onSubmit }) => {
    const id = data.id ?? undefined
    const [country, setCountry] = useState({})
    const [phone, setPhone] = useState(data.phone ?? null)
    const [name, setName] = useState(data.name ?? null)
    const [password, setPassword] = useState(data.id ? null : (Math.random() + 1).toString(36).substring(2))

    const { data: countrySelection, isLoading: isLoadingCountrySelection } = useCountries()

    useEffect(() => {
        if (!isLoadingCountrySelection) {
            const country = countrySelection.find((row) => row.id === (data.country_id ?? 100))

            setCountry(country)
            setPhone(data.phone ? removeCountryCodeFromPhoneNumber(data.phone, country.phonecode) : null)
        }
    }, [isLoadingCountrySelection])

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit({
            id,
            country_id: country?.id,
            initial_phone: data.phone,
            phone: phone ? generatePhoneNumber(country.phonecode, phone) : undefined,
            password,
            name
        })
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div className="col-span-2">
                    <label for="phone" className="block text-xs text-neutral-700">{lang.whatsapp_number}</label>
                    <div className="flex space-x-2">
                        <div className="w-2/3">
                            <SelectCountry disabled={id} onChange={(value) => setCountry(value)} selection={countrySelection ?? []} value={country} display="phonecode" />
                        </div>
                        <Input disabled={id} type="number" onChange={e => setPhone(e.target.value)} value={phone} error={errors.phone} id="phone" />
                    </div>
                    <ErrorMessage error={errors.phone} />
                </div>

                <div>
                    <Label htmlFor="name" value={lang.name} />
                    <Input onChange={e => setName(e.target.value)} error={errors.name} value={name} id="name" />
                    <ErrorMessage error={errors.name} />
                </div>

                <div>
                    <Label htmlFor="password" value={lang.password} />
                    <Input onChange={e => setPassword(e.target.value)} error={errors.password} value={password} id="password" />
                    <Description value="Leave empty if don't want to change the password." error={errors.password} />
                </div>
            </div>
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}

export default Form