import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import axios from "axios"
import { useAuth } from "contexts/auth-context"
import { useLayoutEffect } from "react"
import Form from "./form"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"
import { usePage } from "contexts/page-context"

export default function Create({ title }) {
    const { user, isSuper } = useAuth()
    const { cache } = useSWRConfig()

    const { setTransition } = usePage()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        setTransition(location.state?.transition)

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nProgress.start()

        toast.loading("Loading", {
            id: "submit"
        })

        axios.post(`${process.env.REACT_APP_GENERIC_API_URL}/supplier`, {
            code: data.code,
            name: data.name,
            npwp: data.npwp,
            note: data.note,

            email: data.email,
            fax: data.fax,
            phone: data.phone,
            mobile: data.mobile,
            contact_person: data.contact_person,
            contact_number: data.contact_number,
            address: data.address,
            bill_address: data.bill_address,

            created_by: user?.name
        }).then((response) => {
            cache.clear()
            navigate({ pathname: '/supplier' })
            toast.success(response.data.message, {
                id: "submit"
            })
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nProgress.done()
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                },
                id: "submit"
            })
            console.log(error.response)
        })
    }

    return (
        <Form errors={errors} onSubmit={handleSubmit} />
    )
}