import { CheckIcon, ClipboardIcon } from "components/icons"
import ErrorLayout from "layouts/error-layout"
import { useState } from "react"
import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom"

const Error = () => {
    const error = useRouteError()
    const navigate = useNavigate()

    const [clicked, setClicked] = useState(false)

    var message = "Something went wrong"

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            message = "This page doesn't exist!"
        }

        if (error.status === 401) {
            message = "You aren't authorized to see this"
        }

        if (error.status === 403) {
            message = "Not authorized"
        }

        if (error.status === 503) {
            message = "Looks like our API is down"
        }

        if (error.status === 418) {
            message = "🫖"
        }
    }

    const copy = () => {
        navigator.clipboard.writeText(error?.message)

        setClicked(true)

        setTimeout(() => setClicked(false), 2000)
    }

    return (
        <ErrorLayout title={""}>
            <div className="flex items-center divide-x">
                <div className="px-4">
                    {isRouteErrorResponse(error) ? error.status : 500}
                </div>
                <div className="px-4">
                    {message}
                </div>
            </div>

            {error.message && (
                <div className="fixed text-xs bottom-8">
                    <div className="flex p-2 space-x-4 border select-none rounded-xl bg-neutral-50">
                        <code className="max-w-md m-2 font-medium text-red-400 select-auto">{error?.message}</code>
                        <button onClick={() => copy()} className={`${clicked ? 'bg-green-500 border-green-200' : 'hover:bg-neutral-100 border-neutral-200'} p-2 transition-all border rounded-lg h-fit`}>
                            {clicked ? (
                                <CheckIcon className="w-4 h-4 text-white" stroke={2} />
                            ) : (
                                <ClipboardIcon className="w-4 h-4" stroke={2} />
                            )}
                        </button>
                    </div>
                </div>
            )}

            <div className="mt-16 text-xs">
                <button onClick={() => navigate(-1)}>Go back</button>
            </div>
        </ErrorLayout>
    )
}

export default Error