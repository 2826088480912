import { createBrowserRouter, createHashRouter } from "react-router-dom"

import GuestRoute from "routes/middleware/guest"
import ProtectedRoute from "routes/middleware/protected"

import Dashboard from "pages/dashboard"

import Login from "pages/auth/login"
import Register from "pages/auth/register"
import NoRole from "pages/no-role"
import { show as showDashboard } from "./loader/dashboard"
import { load as loadMainData } from "./loader/main"

import { getByUser as getBranchByUser } from "./loader/branch"
import SelectBranch from "pages/select-branch"
import { lang } from "config"
import Error from "pages/error"

import AppLayout from "layouts/app-layout"

// Pages
import { List as BranchList } from "pages/branch"
import { List as UserList } from "pages/user"
import { List as SupplierList, Create as CreateSupplier, Edit as EditSupplier } from "pages/supplier"
import { List as CustomerList } from "pages/customer"
import { List as RoleList, Create as CreateRole, Edit as EditRole } from "pages/role"
import { List as RoleUserBranchList } from "pages/role-user-branch"

// Loader
import { show as showRole } from "routes/loader/role"
import { show as showSupplier } from "routes/loader/supplier"

export const routeList = [
    // Root loader
    {
        loader: loadMainData,
        errorElement: <Error />,
        children: [
            // Guest
            {
                path: "/register",
                element: (
                    <GuestRoute>
                        <Register title={lang.title.sign_up} />
                    </GuestRoute>
                )
            },
            {
                path: "/login",
                element: (
                    <GuestRoute>
                        <Login title={lang.title.sign_in} />
                    </GuestRoute>
                )
            },

            // Protected
            {
                middleware: "protected",
                element: (
                    <ProtectedRoute>
                        <AppLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: "/",
                        index: true,
                        title: "Dashboard",
                        element: (
                            <Dashboard />
                        ),
                        // loader: showDashboard
                    },
                    {
                        path: "/branch",
                        title: "Branch",
                        element: (
                            <BranchList />
                        )
                    },
                    {
                        path: "/user",
                        title: "User",
                        element: (
                            <UserList />
                        )
                    },
                    {
                        path: "/supplier",
                        title: "Supplier",
                        element: (
                            <SupplierList />
                        )
                    },
                    {
                        path: "/supplier/create",
                        title: "Create Supplier",
                        element: (
                            <CreateSupplier />
                        )
                    },
                    {
                        path: "/supplier/:code/edit",
                        element: (
                            <EditSupplier />
                        ),
                        loader: showSupplier
                    },

                    {
                        path: "/customer",
                        title: "Customer",
                        element: (
                            <CustomerList />
                        )
                    },
                    {
                        path: "/role",
                        title: "Role",
                        element: (
                            <RoleList />
                        )
                    },
                    {
                        path: "/role/create",
                        title: "Create Role",
                        element: (
                            <CreateRole />
                        )
                    },
                    {
                        path: "/role/:id/edit",
                        element: (
                            <EditRole />
                        ),
                        loader: showRole
                    },

                    {
                        path: "/role-user-branch",
                        title: "Role, user, branch map",
                        element: (
                            <RoleUserBranchList />
                        )
                    },
                ]
            },

            {
                middleware: "protected",
                children: [
                    {
                        path: "/branch/select",
                        element: (
                            <ProtectedRoute>
                                <SelectBranch title={lang.title.select_branch} />
                            </ProtectedRoute>
                        ),
                        loader: getBranchByUser
                    },
                    {
                        path: "/no-role",
                        element: (
                            <ProtectedRoute>
                                <NoRole title="No Role Assigned" />
                            </ProtectedRoute>
                        )
                    }
                ]
            }
        ]
    }
]

export const routes = process.env.NODE_ENV === 'development' ? createBrowserRouter(routeList) : createHashRouter(routeList)

export default routes