import { useEffect, useState } from "react"
import { Link, useLoaderData, useLocation, useNavigate, useOutletContext } from "react-router-dom"

import axios from "axios"
import { useAuth } from "contexts/auth-context"
import { useLayoutEffect } from "react"
import Form from "./form"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"
import { usePage } from "contexts/page-context"

export default function Edit() {
    const { user } = useAuth()
    const { cache } = useSWRConfig()

    const { setTransition } = usePage()

    const { setTitle } = useOutletContext()

    const data = useLoaderData()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        setTransition(location.state?.transition)
        setTitle(`Edit ${data?.name}`)

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nProgress.start()

        toast.loading("Loading", {
            id: "submit"
        })

        axios.patch(`${process.env.REACT_APP_GENERIC_API_URL}/role/${data?.id}`, {
            application_id: data.application_id,
            name: data.name,
            permission: data.permission,
            updated_by: user?.name
        }).then((response) => {
            cache.clear()
            navigate({ pathname: '/role' })
            toast.success(response.data.message, {
                id: "submit"
            })
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nProgress.done()
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                },
                id: "submit"
            })
            console.log(error.response)
        })
    }

    return (
        <Form data={data} errors={errors} onSubmit={handleSubmit} />
    )
}